import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import { VideoPlayer } from "@components/video"

import {
  ScrollToButton, LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Headphone Treats | Quirk Peddler & Champion of Striking Kooks"
        ogDescription="Headphone Treats is an Atlanta, GA-based record label founded by producer, engineer and recording artist, Jimmy Ether. We like eclectic sounds, infectious melody, clever lyrics and people with hearts installed in roughly the right places."
        url="https://headphonetreats.com"
      />
      <PageWrapper color="burgandy">
        <TextBlock
          textPadded
          textLeft={
            <div>
                <h1>Headphone Treats</h1>
                <h2>Quirk Peddler & Champion of Striking Kooks</h2>
                <p>
                  Headphone Treats is an Atlanta, GA-based record label founded by producer, engineer and recording artist, <a href="https://jimmyether.com" target="_blank">Jimmy Ether</a>. We like eclectic sounds, infectious melody, clever lyrics and people with hearts installed in roughly the right places.
                </p>
            </div>
          }
          textRight={
            <div>
              <img src="/images/headphone-treats-logo-white.png" alt="Headphone Treats record label Atlanta GA" />
            </div>
          }
        />
        <SingleColumnBlock
          textPadded
          centered
          text={
            <div>
              <h2>Recent Releases</h2>
              <p>Below you can find links to all the download and streaming platforms for our recent releases. Be sure to check out the band sites for more info in the navigation under "Roster".</p>
            </div>
          }
        />
      </PageWrapper>
      <PageWrapper color="pink">
        <SingleColumnBlock
          textPadded
          centered
          text={
            <div>
              <h2>Loud Humans</h2>
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div id="music">
              <img src="/images/loud-humans-see-lioning.jpg" alt="see, lioning EP by Loud Humans" />
              <p className="small">Artwork by Deral Fenderson.</p>
            </div>
          }
          textRight={
            <div>
              <h3>"see, lioning" EP out now</h3>
              <p>
                The debut EP from Loud Humans with 5 original tracks plus a cover of possibly the greatest song of all time, "Wichita Lineman".
              </p>
              <h4>Download</h4>
              <p>
                Download in lossless or lossy format of your choice at <a href="https://loudhumans.bandcamp.com/" target="_blank">Bandcamp</a>.
              </p>
              <h4>Stream</h4>
              <p>
                Pelt us with fractions of pennies by streaming over your music preview platform of choice:<br />
                <a href="https://open.spotify.com/album/1zpcjhpfq9AvQ31pYO2Ozn" target="_blank">Spotify</a><br />
                <a href="https://music.apple.com/us/album/see-lioning-ep/1643137378?uo=4" target="_blank">Apple Music</a><br />
                <a href="https://tidal.com/browse/artist/34193007" target="_blank">Tidal</a><br />
                <a href="https://amazon.com/music/player/albums/B0BCX14RLV?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_XOmNcQnOCpUkPyAj8P4W7mjtF" target="_blank">Amazon Music</a><br />
                <a href="https://www.deezer.com/us/album/352861047" target="_blank">Deezer</a><br />
                <a href="https://www.youtube.com/channel/UCywMr39ACVc6e6hmp-U4drg" target="_blank">YouTube Music</a><br />
                <a href="https://www.qobuz.com/us-en/album/see-lioning-loud-humans/lyu2szchgo83b" target="_blank">Qobuz</a>
              </p>
            </div>
          }
        />
      </PageWrapper>
      <PageWrapper color="brown">
        <SingleColumnBlock
          textPadded
          centered
          text={
            <div>
              <h2>The Soogs</h2>
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>"Level Terrace Appartment" EP out now</h3>
              <p>
                The second release by The Soogs.
              </p>
              <h4>Download</h4>
              <p>
                Download in lossless or lossy format of your choice at <a href="https://thesoogs.bandcamp.com/" target="_blank">Bandcamp</a>.
              </p>
              <h4>Stream</h4>
              <p>
                Pelt us with fractions of pennies by streaming over your music preview platform of choice:<br />
                <a href="https://open.spotify.com/album/1CCJpawjJgiafgf7r3okS6?si=BrePu0_fT76nrTPCBZ98tA" target="_blank">Spotify</a><br />
                <a href="https://music.apple.com/us/album/level-terrace-apartment/1639263192" target="_blank">Apple Music</a><br />
                <a href="https://tidal.com/browse/album/242565050" target="_blank">Tidal</a><br />
                <a href="https://amazon.com/music/player/albums/B0B99YZZ1N?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_DPGZxUxHuHImM9ugCU40b7hJY" target="_blank">Amazon Music</a><br />
                <a href="https://www.deezer.com/us/album/345411917" target="_blank">Deezer</a><br />
                <a href="https://www.youtube.com/watch?v=FwCp_L2XZp8&list=OLAK5uy_mywyiLHMtXv1pUABsPXFfBPsKpxiXzInk" target="_blank">YouTube Music</a><br />
                <a href="https://www.qobuz.com/us-en/album/level-terrace-apartment-the-soogs/cn8ft5qxp13qb" target="_blank">Qobuz</a>
              </p>
            </div>
          }
          textRight={
            <div>
              <img src="/images/the-soogs-level-terrace-apartment.jpg" alt="Level Terrace Apartment EP by The Soogs" />
            </div>
          }
        />
      </PageWrapper>
      <PageWrapper color="charcole">
        <SingleColumnBlock
          textPadded
          centered
          text={
            <div>
              <h2>Victory Hands</h2>
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/victory-hands-BRADEN.jpg" alt="BRADEN 7-inch by Victory Hands" />
              <p className="small">Illustrations by Bob Rob Medina.</p>
            </div>
          }
          textRight={
            <div>
              <h3>BRADEN 7"</h3>
              <p>
                This is the first release as a 4-peice. Featuring "Everyone Loves A Parade" and "A Man Named Hope".
              </p>
              <h4>Download</h4>
              <p>
                Download in lossless or lossy archival format of your choice at <a href="https://victoryhands.bandcamp.com/" target="_blank">Bandcamp</a>.
              </p>
              <h4>Stream</h4>
              <p>
                <a href="https://open.spotify.com/album/1QV90siIw6OOxBp7jVFF4l" target="_blank">Spotify</a><br />
                <a href="https://music.apple.com/us/album/braden-single/1622698110" target="_blank">Apple Music</a><br />
                <a href="https://tidal.com/browse/album/227717606" target="_blank">Tidal</a><br />
                <a href="https://www.amazon.com/music/player/albums/B09ZNTRL11" target="_blank">Amazon Music</a><br />
                <a href="https://www.deezer.com/us/album/316398587" target="_blank">Deezer</a><br />
                <a href="https://www.youtube.com/watch?v=zljd101vo68&list=OLAK5uy_mEwlP3emv3IvmnFngGU3Qm8sT3BPQlFfI" target="_blank">YouTube Music</a><br />
                <a href="https://www.qobuz.com/us-en/album/braden-victory-hands/nyw1xpzbbgmwb" target="_blank">Qobuz</a>
              </p>
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>BISHOP 12"</h3>
              <p>
                This release is the third and final in the original trio format. Featuring "Top Brass", "Dressed To The Tease", "Face These Facts", "This Kitchen", "Tonight He Stands", and "All In The Family".
              </p>
              <h4>Download</h4>
              <p>
                Download in lossless or lossy archival format of your choice at <a href="https://victoryhands.bandcamp.com/" target="_blank">Bandcamp</a>.
              </p>
              <h4>Stream</h4>
              <p>
                <a href="https://open.spotify.com/album/0RxNTGur2Wy7SSlEFER4TO" target="_blank">Spotify</a><br />
                <a href="https://music.apple.com/us/album/bishop-ep/1626221640" target="_blank">Apple Music</a><br />
                <a href="https://tidal.com/browse/album/230933709" target="_blank">Tidal</a><br />
                <a href="https://www.amazon.com/music/player/albums/B0B2CTY88B" target="_blank">Amazon Music</a><br />
                <a href="https://www.deezer.com/us/album/322161927" target="_blank">Deezer</a><br />
                <a href="https://www.youtube.com/watch?v=TUjVNatp2Os&list=OLAK5uy_m1EwEglqJIL8EOM0474bIz9GTzSLEk4vQ" target="_blank">YouTube Music</a><br />
                <a href="https://www.qobuz.com/us-en/album/bishop-victory-hands/sd5bffpusk7ib" target="_blank">Qobuz</a>
              </p>
            </div>
          }
          textRight={
            <div>
              <img src="/images/victory-hands-BISHOP.jpg" alt="BISHOP 12-inch by Victory Hands" />
              <p className="small">Illustrations by Louie Crumbley.</p>
            </div>
          }
        />
      </PageWrapper>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>Got something to say? Need something? Wanna chat?</h2>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default IndexPage
